@import url('https://fonts.googleapis.com/css2?family=VT323');

.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    max-width: 100%;
    height: auto;
  }
}

.App-logo-mobile {
  background-image: url("/src/logo.png");
  max-width: 50%;
  height: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.heroInfo {
  padding-top: 120px;
}

.App-link {
  color: #61dafb;
}

body, html {
  height: 100%;
  width: auto;
}
.bg {
  background-image: url("/src/images/background.jpg");
}

.nav-link {
  font-family: 'VT323', sans-serif;
  font-size: 25pt;
  text-transform: uppercase;
  margin-right: 25px;
  color: #9349e6 !important;
}

.nav-link:hover {
  font-family: 'VT323', sans-serif;
  font-size: 25pt;
  text-transform: uppercase;
  text-decoration: underline;
  color: #9349e6 !important;
}

.btn .btn-primary .btn-secondary .button{
  font-family: "Times New Roman";
}

.footer{
  background-color: #e969f4;
  min-height: 100px;
  color: #ffffff;
}

.footerlogo {
  padding-bottom: 40px;
}

.copyright {
  text-align: center;

}

h1 {
  font-family: "Times New Roman";
  padding-bottom: 20px;
  font-weight: bold;
}

h2{
  font-family: "Times New Roman";
  padding-bottom: 20px;
  font-weight: bold;
}

.footerMenu {
  font-family: "Times New Roman";
  color: white;
  text-align: left;
  font-size: large;
}

.footerLink{
  color: white !important;
  text-decoration: none;
}

.footerLink:hover{
  color: white !important;
  text-decoration: underline;
}

.goats {
  padding-top: 45px;
}

.goat {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  display: block;
}

.goatContainer {
  position: relative;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.goatContainer:hover .goat {
  opacity: .9;
}

.goatContainer:hover .middle {
  opacity: 1;
}

.goatText {
  background-color: #0275d8;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.padding-0{
  padding-right:0;
  padding-left:0;
}
